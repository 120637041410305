<template>
  <div class="litID">

    <header-lit-container name="Александр Сергеевич Пушкин"
                            v-bind:is-transition="false"></header-lit-container>

    <div id="litID_main_wrapper">

      <div id="litID_main">

        <div id="litID_main_content">

          <transition name="content_photo_move" appear>
            <div id="litID_main_title">
              {{ nameID }}
            </div>
          </transition>


          <ul id="litID_main_text">
            <li v-for="line in text" :key="line">
              <transition name="content_photo_move" appear>
              <div>
                {{ line }}
              </div>
              </transition>
            </li>
            <li style="margin-top: 1rem">
              {{year}} г.
            </li>
          </ul>

      </div>

        <transition name="slideLineText" appear>
          <div id="content_photo">

            <img :src = photo class="content_item_photo" alt="Фото учителя">

          </div>
        </transition>

      </div>

    </div>

  </div>
</template>

<script>
//import headerLitContainerMini from "@/components/headerLitContainerMini.vue";
import headerLitContainer from "@/components/headerLitContainer.vue";

export default {
  name: "LitID",
  components: {headerLitContainer},
  data() {
    return {
    }
  },
  mounted() {
  },
  computed: {
    litId()  {
      return this.$route.params.id;
    },
    litItems() {
      return this.$store.getters.getLitItems
    },
    nameID : function () {
      return this.litItems.find(item => item.itemID === this.litId).title
    },
    text : function () {
      return this.litItems.find(item => item.itemID === this.litId).text
    },
    photo : function () {
      return this.litItems.find(item => item.itemID === this.litId).photo
    },
    year : function () {
      return this.litItems.find(item => item.itemID === this.litId).year
    }
  },
}
</script>

<style scoped>

.litID {
  width:                  100%;
  max-width:              100%;
  background-color:       #fdf5e6;
  margin-top:             var(--header-height);

}

#litID_main_wrapper {
  display:                grid;
  grid-template-columns:  1fr;
  max-width:              var(--width-wrapper-small);
  height:                 auto;
  min-height: 100vh;
  padding-left:           .5rem;
  padding-right:          .5rem;
  margin:                 1rem auto 1rem auto;
}

@media (min-width: 60rem) {
  #litID_main_wrapper {
    max-width:              var(--width-wrapper-middle);
  }
}
@media (min-width: 75rem) {
  #litID_main_wrapper {
    max-width:              var(--width-wrapper-large);
  }
}

#litID_main {
  display: 			          grid;
  grid-template-columns:  1fr;
  grid-gap:               1rem;
  max-width:              100%;
  min-width:              100%;
  margin-top:             2rem;
  margin-bottom:          2rem;
  justify-self:           left;
  grid-template-rows:     auto auto;
  font-weight:            400;
}

@media (min-width: 60rem) {
  #litID_main {
    grid-template-columns:  minmax(calc(var(--width-wrapper-middle)/2), 1fr) 1fr;
    grid-template-rows:     minmax(495px, auto);
    justify-content:        left;
    align-content:          flex-start;
    max-width:              var(--width-wrapper-middle);
  }
}

@media (min-width: 75rem) {
  #litID_main {
    grid-template-columns:  minmax(calc(var(--width-wrapper-large)/2), 1fr) 1fr;
    grid-template-rows:     minmax(495px, auto);
    justify-content:        left;
    align-content:          flex-start;
    max-width:              var(--width-wrapper-large);
  }
}

#litID_main_title {
  color:                  #555;
  font-size:              var(--menu-item-font-size-20);
  margin-top:            -15px;
  padding-bottom:         1rem;
}

#litID_main_text {
  color:                  #555;
  font-size:              var(--menu-item-font-size-20);
}

@media (min-width: 60rem) {

  #litID_main_title {
    font-size:               calc(1.2*var(--menu-item-font-size-25));
  }

  #litID_main_text {
    font-size:              calc(1*var(--menu-item-font-size-25));
  }
}

.slideLineText-enter-active {
  transition: all 1s ease;
}
.slideLineText-leave-active {
}
.slideLineText-enter-from {
  transform: translateX(200px);
  opacity: 0;
}
.slideLineText-leave-to {
}

#content_photo {
  display:                none;
}

#content_photo > img {
  width:                  100%;
  height:                 100%;
  object-fit:             cover;
  overflow:               hidden;
  border-radius:          22px;
}

@media (min-width: 60rem) {
  #content_photo {
    display:                grid;
    grid-template-columns:  330px;
    grid-template-rows:     330px;
    width:                  330px;
    height:                 330px;
    max-height:             330px;
    background:             none;
    place-items:            end;
    overflow:               hidden;
    border-radius:          22px;
    justify-self:           right;
    align-self:             start;
  }
}

@media (min-width: 75rem) {
  #content_photo {
    display:                grid;
    grid-template-columns:  495px;
    grid-template-rows:     495px;
    width:                  495px;
    height:                 495px;
    max-height:             495px;
    background:             none;
    place-items:            end;
    overflow:               hidden;
    border-radius:          22px;
    justify-self:           right;
    align-self:             start;
  }
}

.content_photo_move-enter-active {
  transition: all 1s ease-out;
}

.content_photo_move-leave-active {
  transition: all 1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.content_photo_move-enter-from,
.content_photo_move-leave-to {
  transform: translateX(-200px);
  opacity: 0;
}

.content_name_move-enter-active {
  transition: all 1s ease-out;
}

.content_name_move-leave-active {
  transition: all 1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.content_name_move-enter-from,
.content_name_move-leave-to {
  transform: translateX(200px);
  opacity: 0;
}

</style>